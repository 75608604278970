import { Component, OnInit } from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { mappingRoutes } from 'src/app/core/mappingRoutes';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TitleSectionComponent } from '../../../../shared/widgets/title-section/title-section.component';

@Component({
    selector: 'weni-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less'],
    standalone: true,
    imports: [
        TitleSectionComponent,
        NzIconModule,
        NzButtonModule,
        FormsModule,
        RouterLinkActive,
    ],
})
export class FooterComponent implements OnInit {
  ngOnInit(): void {}

  constructor(private router: Router) {}

  goToFaqs() {
    this.router.navigate([mappingRoutes.portal.faqs]);
  }

  goHome() {
    document.getElementById('home')?.scrollIntoView({ behavior: 'smooth' });
    this.router.navigate([mappingRoutes.portal.home]);
  }
  goZOne() {
    document.getElementById('zone')?.scrollIntoView({ behavior: 'smooth' });
  }
  contact() {
    document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
  }

  goCgu(){
    this.router.navigate([mappingRoutes.portal.cgu]);
  }
  privacy(){
    this.router.navigate([mappingRoutes.portal.privacy]);
  }

  supp(){
    this.router.navigate([mappingRoutes.portal.delete]);
  }
}
