import { Component, OnInit, ElementRef, Renderer2, ViewChild, } from '@angular/core';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { mappingRoutes } from 'src/app/core/mappingRoutes';

import * as AOS from "aos";

@Component({
    selector: 'weni-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink]
})
export class HeaderComponent implements OnInit {

  ismobileOpen: boolean = false;

  @ViewChild("hamburger", { static: false }) toggler: ElementRef | undefined;
  @ViewChild("navLinksContainer", { static: false }) navLinksContainer:
    | ElementRef
    | undefined;


  ngOnInit(): void {
    AOS.init();
  }

  constructor(private router: Router,  private renderer: Renderer2) {}

  toggleNav() {
    if (this.toggler && this.navLinksContainer) {
      // Animation du bouton

      this.renderer.addClass(this.toggler.nativeElement, "open");

      const ariaToggle =
        this.toggler.nativeElement.getAttribute("aria-expanded") === "true"
          ? "false"
          : "true";
      this.ismobileOpen = !this.ismobileOpen;
      console.log(this.toggler.nativeElement.getAttribute("aria-expanded"));

      if (this.ismobileOpen) {
        this.renderer.setAttribute(
          this.toggler.nativeElement,
          "aria-expanded",
          ariaToggle
        );

        // Slide de la navigation
        this.renderer.addClass(this.navLinksContainer.nativeElement, "open");
      } else {
        this.removeSideBar();
      }
    }
  }

  removeSideBar() {
    const ariaToggle =
      this.toggler?.nativeElement.getAttribute("aria-expanded") === "true"
        ? "false"
        : "true";
    this.renderer.removeAttribute(this.toggler?.nativeElement, "aria-expanded");
    // Slide de la navigation
    this.renderer.removeClass(this.navLinksContainer?.nativeElement, "open");
    this.renderer.removeClass(this.toggler?.nativeElement, "open");
    this.toggler?.nativeElement.setAttribute("aria-expanded", false);
  }

  isActiveRoute(pathName: string): string {
    return this.router.isActive(pathName, true) ? "active" : "";
  }

  goToHome() {
    this.router.navigate([mappingRoutes.portal.home]);
    this.removeSideBar();
  }
  goToZone() {
     document.getElementById("zone")?.scrollIntoView({behavior:'smooth'});
     this.removeSideBar();
  }

  goTofonction() {
    document.getElementById("fonctionnalite")?.scrollIntoView({behavior:'smooth'});
    this.removeSideBar();
  }

  goToMarche() {
    document.getElementById("walk")?.scrollIntoView({behavior:'smooth'});
    this.removeSideBar();
  }
  goToLvraison(){
    document.getElementById("livraison")?.scrollIntoView({behavior:'smooth'});
    this.removeSideBar();
  }
  goToContact(){
    document.getElementById("contact")?.scrollIntoView({behavior:'smooth'});
    this.removeSideBar();
  }
 

  // goHome(){
  //   document.getElementById("home")?.scrollIntoView({behavior:'smooth'});
  //   this.router.navigate([mappingRoutes.portal.home]);
  // }

  // goZone(){
  //   document.getElementById("zone")?.scrollIntoView({behavior:'smooth'});
    
  // }
  // customMade(){
  //   document.getElementById("fonctionnalite")?.scrollIntoView({behavior:'smooth'});
  // }
  // walk(){
  //   document.getElementById("walk")?.scrollIntoView({behavior:'smooth'});
  // }
  // contact(){
  //   document.getElementById("contact")?.scrollIntoView({behavior:'smooth'});
  // }
  // livraison(){
  //   document.getElementById("livraison")?.scrollIntoView({behavior:'smooth'});
  // }
}
