import { Component } from '@angular/core';
import { FooterComponent } from './component/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './component/header/header.component';

@Component({
    selector: 'weni-layout-portal',
    templateUrl: './layout-portal.component.html',
    styleUrls: ['./layout-portal.component.less'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, FooterComponent]
})
export class LayoutPortalComponent {

}
