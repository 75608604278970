<div class="footer-app">
  <div class="groupApp">
    <div class="footerApp ">
      <weni-title-section
        [color]="'#FFF'"
        [title]="'Livraison sur mesure'"
      >
      </weni-title-section>
      <div class="Custom-delivery ">
        <img alt="" class="img_footer" src="/assets/images/phone-with-logo.svg">
        <div class="check">
          <div class="checkOne weni-d-flex">
            <p class="ronde"><i nz-icon nzTheme="outline" nzType="check"></i></p>
            <p class="message">Envoyer une grande quantité de colis</p>
          </div>
          <div class="checkOne weni-d-flex">
            <p class="ronde"><i nz-icon nzTheme="outline" nzType="check"></i></p>
            <p class="message">Envoyer un colis à l’intérieur</p>
          </div>
          <div class="checkOne weni-d-flex">
            <p class="ronde"><i nz-icon nzTheme="outline" nzType="check"></i></p>
            <p class="message">Autre expédition importante…</p>
          </div>
        </div>
      </div>
    </div>
    <hr class="hr">
    <div class="footer-form ">
      <weni-title-section
        [color]="'#FFF'"
        [title]="'Envoyez nous un message'"
      >
      </weni-title-section>
      <form class=" forms">
        <div class="formGroup">
          <label for="name">Nom complet</label>
          <input placeholder="Entrer votre nom complet" required type="text">
        </div>
        <div class="formGroup">
          <label for="email">Email</label>
          <input placeholder="Entrez votre adresse email" required type="email">
        </div>
        <div class="formGroup">
          <label for="Objet">Objet</label>
          <input placeholder="Objet" required type="text">
        </div>
        <div class="formGroup">
          <label for="message">Votre commentaire ou message</label>
          <textarea cols="33" id="story" name="story" placeholder="Message" rows="5"></textarea>
          <!-- <input type="textarea" placeholder="Objet" required> -->
        </div>
      </form>
      <br>
      <button class="weni-bouton " type="button">Envoyer</button>
    </div>

  </div>
  <div class="basPge ">
    <img alt="" class="logoFooter" src="/assets/logo.png">
    <ul class="weni-d-flex">
      <li><a (click)="goHome()" routerLinkActive="active">Accueil</a></li>
      <li><a (click)="goCgu()">CGU</a></li>
      <li><a (click)="goToFaqs()">FAQ</a></li>
      <li><a (click)="privacy()">Privacy </a></li>
      <li><a (click)="supp()">Suppression de comptes </a></li>
    </ul>
  </div>

</div>
