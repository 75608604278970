<div class="header-weni weni-w-100" data-aos="fade-up" data-aos-duration="1000">
  <nav>
    <a href="#" class="nav-icon" aria-label="homepage" aria-current="page">
      <img src="/assets/logo.svg" alt="chat icon" />
    </a>
    <div class="main-navlinks">
      <button
        type="button"
        (click)="toggleNav()"
        class="hamburger"
        #hamburger
        aria-label="Toggle Navigation"
        aria-expanded="false"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div class="navlinks-container" #navLinksContainer>
        <a
          routerLinkActive="active" 
          [routerLinkActiveOptions]="{exact :true}"
          routerLink="/home"
          (click)="goToHome()"
          aria-current="page"
          >Accueil</a
        >

        <a 
        routerLinkActive="active" 
        [routerLinkActiveOptions]="{exact :true}"
        routerLink="/zoneLivraison" 
        (click)="goToZone()"
          >Zone de livraison</a
        >
        <a
          routerLinkActive="active"
          routerLink="/portal/lutte"
          (click)="goTofonction()"
          >Fonctionnalité</a
        >
        <a
          routerLink="/portal/lutte"
          routerLinkActive="active"
          (click)="goToMarche()"
          >Comment ça marche ?</a
        >
        <a
          routerLink="/portal/lutte"
          routerLinkActive="active"
          (click)="goToLvraison()"
          >Livraison</a
        >
        <a
          routerLink="/portal/lutte"
          routerLinkActive="active"
          (click)="goToContact()"
          >Contact</a
        >
      </div>
    </div>
    <div class="nav-authentication">
      <!-- <a href="#" class="user-toggler" aria-label="Sign in page">
              <img src="/assets/images/user.svg"/>
            </a> -->
      <div class="sign-btns">
        <button type="button">Business</button>
      </div>
    </div>
  </nav>

  <!-- 
    <nav>
        <a href="#" class="nav-icon" aria-label="homepage" aria-current="page">
            <img src="/assets/logo.png" alt="">
        </a>
        <div class="main-navlinks">
          <button
            type="button"
           
            class="hamburger"
            #hamburger
            aria-label="Toggle Navigation"
            aria-expanded="false"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div class="navlinks-container" #navLinksContainer>
            <a
              routerLink="/portal/home"
              routerLinkActive="active"
            
              aria-current="page"
              >Accueil</a
            >
    
            <a
              routerLink="/portal/action"
              routerLinkActive="active"
             
              >Nos actions</a
            >
            <a
              routerLink="/portal/lutte"
              routerLinkActive="active"
            
              >Comment lutter</a
            >
          </div>
        </div>
        <div class="nav-authentication">
         
          <div class="sign-btns">
            <button  type="button">Faire un don</button>
          </div>
        </div>
      </nav> -->
  <!-- <nav>
        <input type="checkbox" name="" id="check">
        <label for="check" class="ckeckBtn">
            <i nz-icon nzType="menu-fold" nzTheme="outline"></i>
        </label>
        <img src="/assets/logo.png" alt="">
        <ul class="weni-float-right poppins-font">
            <li><a class="active" (click)="goHome()" routerLinkActive="active" >Accueil</a></li>
            <li><a (click)="goZone()" routerLinkActive="active">Zone de livraison</a></li>
            <li><a (click)="customMade()" routerLinkActive="active">Fonctionnalité</a></li>
            <li><a (click)="walk()" routerLinkActive="active">Comment ça marche ?</a></li>
            <li><a (click)="livraison()"  routerLinkActive="active">Livraison</a></li>
            <li><a (click)="contact()" routerLinkActive="active">Contact</a></li>
            
            <button class="weni-bouton" type="button">Business</button>
        </ul>
      
    </nav> -->
</div>
