import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LOCALE_ID, DEFAULT_CURRENCY_CODE, importProvidersFrom } from '@angular/core';
import { ErrorInterceptor } from './app/core/helpers/error.interceptor';
import { JwtInterceptor } from './app/core/helpers/jwt.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { ngZorroConfig } from './app/core/ngZorroConfig';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NZ_I18N, fr_FR } from 'ng-zorro-antd/i18n';
import { Routes, provideRouter } from '@angular/router';
import { LayoutPortalComponent } from './app/layouts/layout-portal/layout-portal.component';

const routes: Routes = [
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full',
    },
    {
      path: '',
      component: LayoutPortalComponent,
      loadChildren: () =>
        import('./app/landing-pages/page-routes'),
    },
  
   
  ];
bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule),
        provideRouter(routes),
        { provide: NZ_I18N, useValue: fr_FR },
        { provide: NZ_CONFIG, useValue: ngZorroConfig },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR',
        },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'CFA' },
        // {provide: LocationStrategy, useClass: HashLocationStrategy},
        NzMessageService,
        NzNotificationService,
        NzModalService,
        NzDrawerService,
        NzImageService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),

    ]
})
  .catch(err => console.error(err));
