import {Component, Input, OnInit} from '@angular/core';
import * as AOS from "aos";
import { NgStyle } from '@angular/common';
@Component({
    selector: 'weni-title-section',
    templateUrl: './title-section.component.html',
    styleUrls: ['./title-section.component.less'],
    standalone: true,
    imports: [NgStyle]
})
export class TitleSectionComponent  implements OnInit{

  ngOnInit(): void {
    AOS.init();
  }

  @Input('title') title!: string;
  // @Input('preTitle') preTitle!: string;
  @Input('color') color: string | undefined = '#000';
}
